import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  xs: '1px',
  sm: '376px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
});

export default breakpoints;
