import { extendTheme, Theme as ChakraTheme } from '@chakra-ui/react';

import components from './components';
import foundations from './foundations';
import styles from './styles';

/**
 * Color mode config
 */
const config: ChakraTheme['config'] = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'v4c-widget',
};

const base = {
  ...foundations,
  components,
  config,
  styles,
  fonts: {
    heading: 'Neutrif Pro',
    body: 'Neutrif Pro',
  },
};
const theme = extendTheme(base);

export type Theme = typeof base & ChakraTheme;

export default theme;
