import { FC } from 'react';

import { Text } from '@chakra-ui/react';

import { useAppConfig } from '@/context/AppConfigContext';
import { useFormsyQuery } from '@/generated/graphql';

const HomeView: FC = () => {
  const { config } = useAppConfig();
  const { data, loading, error } = useFormsyQuery({
    context: {
      headers: {
        "x-formsy-clientApiKey": config.apiKey,
        "x-formsy-clientFormId": config.formId
      }
    }
  });

  if (loading) {
    return 'loading...'
  }

  if (error) {
    return <div>{error.message}</div>
  }

  return (
    <Text>{data?.formsy?.formId}</Text>
  );
};

export default HomeView;
