export default {
  variants: {
    ghost: {
      color: 'purple.900',
      backgroundColor: 'transparent',
      borderColor: 'transparent',

      _hover: {
        color: 'purple.900',
        borderColor: 'transparent',
      },
    },
    action: {
      color: 'white.400',
      fill: 'white.400',
      background: 'purple.400',
      borderColor: 'purple.400',
      width: '4rem',
      height: '4rem',

      ' svg > *': {
        fill: 'white.400',
      },

      _hover: {
        color: 'white.400',
        fill: 'white.400',
        background: 'purple.500',
        borderColor: 'purple.500',
        boxShadow: 'none',

        _disabled: {
          _hover: {
            background: 'gray.300',
            borderColor: 'gray.300',
            fill: 'charcoal.200',
            color: 'charcoal.200',
            boxShadow: 'none',
            opacity: 1,
          },
        },
      },
      _active: {
        fill: 'white.400',
        color: 'white.400',
        background: 'purple.500',
        borderColor: 'purple.500',
      },
      _disabled: {
        background: 'gray.300',
        borderColor: 'gray.300',
        fill: 'charcoal.200',
        color: 'charcoal.200',
        boxShadow: 'none',
        opacity: 1,
      },
    },
  },
};
