import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import Home from '@/components/Router/Home';
import { useRouter } from '@/context/RouterContext';

const Router: FC = () => {
  const { route } = useRouter();
  const { path } = route;

  return (
    <AnimatePresence mode='wait'>
      {path === '/' && <Home />}
    </AnimatePresence>
  );
};

export default Router;
