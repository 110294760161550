export default {
  white: { 400: '#fff' },
  purple: {
    900: '#110033',
    500: '#4e00e5',
    400: '#5700FF',
    350: '#D0B8FF',
    300: '#938EA5',
    200: '#E7DEFF',
    150: '#E5E0EB',
    100: '#efeef5',
    50: '#EEE5FF',
  },
  red: { 400: '#E53E3E' },
  cyan: { 400: '#00CCC5' },
  charcoal: { 400: '#48454F', 300: '#AAA6B6FF', 200: '#1C1B1E32' },
  gray: { 400: '#1F1F1F', 300: '#1F1F1F12', 150: '#F5F5F5', 100: '#FCFAFF' },
};
