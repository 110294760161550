import { Styles } from '@chakra-ui/theme-tools';

// prevent default theme global overrides
const styles: Styles = {
  global: () => ({
    body: {
      fontFamily: 'Neutrif Pro',
      color: '',
      bg: '',
      transitionProperty: '',
      transitionDuration: '',
      lineHeight: '',
    },
    '*::placeholder': {
      color: '',
    },
    '*, *::before, &::after': {
      borderColor: '',
      wordWrap: '',
    },
  }),
};

export default styles;
