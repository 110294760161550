import React, { FC, ReactNode, useCallback, useState } from 'react';

type Route = {
  path: string;
  key: string;
};

type RouterContextType = {
  route: Route;
  history: Route[];
  push: (path: string) => void;
  replace: (path: string) => void;
};

const initialRoute = { path: '/', key: Math.random().toString() };

const RouterContext = React.createContext<RouterContextType>({
  route: initialRoute,
  history: [initialRoute],
  push: () => undefined,
  replace: () => undefined,
});

const RouterProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [history, setHistory] = useState([initialRoute]);
  const route = history[history.length - 1];
  const push = useCallback(
    (path: string) => setHistory(st => [...st, { path, key: Math.random().toString() }]),
    []
  );
  const replace = useCallback(
    (path: string) =>
      setHistory(st => [
        ...st.slice(0, Math.max(st.length - 1, 0)),
        { path, key: Math.random().toString() },
      ]),
    []
  );

  return (
    <RouterContext.Provider
      value={{
        route,
        history,
        push,
        replace,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};

function useRouter(): RouterContextType {
  const context = React.useContext(RouterContext);
  if (context === undefined) {
    throw new Error('useRouter must be used within a RouterProvider');
  }
  return context;
}

export { RouterProvider, useRouter };
