const typography = {
  fontWeights: {
    light: 300,
    normal: 500,
    bold: 700,
  },

  fonts: {
    body: 'Neutrif Pro, system-ui, sans-serif',
    heading: 'inherit',
  },

  fontSizes: {
    sm: 12.8,
    base: 16,
    md: 20,
    lg: 25,
    xl: 31.25,
    xxl: 39.0625,
  },
};

export type Typography = typeof typography;

export default typography;
