import React from 'react';

import { ColorModeScript } from '@chakra-ui/react';
import ReactDOM from 'react-dom';

import { defaultConfig } from '@/context/AppConfigContext';
import theme from '@/theme';
import { AppConfig } from '@/types/domain.d';

import App from './App';

const init = (config: AppConfig) => {
  let element;

  if (config?.selector) {
    element = document.querySelector(config.selector);
  } else {
    element = document.getElementById(defaultConfig.selector as string);
    if (!element) {
      element = document.createElement('div');
      element.style.zIndex = '99999';
      element.id = 'Formsy__widget';
      document.body.append(element);
    }
  }
  if (element) (element as any).style.zIndex = '99999';

  if (element) {
    ReactDOM.render(
      <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App config={config} />
      </React.StrictMode>,
      element
    );
  }
};

if (!window.Formsy) {
  window.Formsy = {} as any;
}

window.Formsy.init = init;

if (window.Formsy.onLoad) {
  window.Formsy.onLoad();
}

if (document.currentScript?.dataset?.config) {
  try {
    init(JSON.parse(document.currentScript.dataset.config));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
