import { useMemo } from 'react';

import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

let apolloClient: ApolloClient<any>;

function createApolloClient() {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      return;
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(async ({ message }) => {
        Sentry.addBreadcrumb({
          message: `[GraphQL error]: Message: ${message}`,
        });
      });
    }
    if (networkError) {
      Sentry.addBreadcrumb({ message: `[Network error]: ${networkError}` });
    }
  });

  const API_URL = process.env.NEXT_PUBLIC_CORE_API;

  const uploadLink = createHttpLink({ uri: API_URL });
  const links = from([errorLink, uploadLink]);
  return new ApolloClient({
    link: links,
    cache: new InMemoryCache({
      typePolicies: {
        SystemMonitoringStatus: {
          keyFields: ['cacheId'],
        },
        UserMonitoringStatus: {
          keyFields: ['userId'],
        },
      },
    }),
  });
}

export function initializeApollo(initialState = {}) {
  const ac = apolloClient ?? createApolloClient();
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = ac.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    ac.cache.restore({ ...existingCache, ...initialState });
  }
  if (!apolloClient) {
    apolloClient = ac;
  }
  return ac;
}

export function useApollo(initialState = {}) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
