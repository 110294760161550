import { FC, ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

import { RouterProvider } from '@/context/RouterContext';
import { useApollo } from '@/graphql/apollo';
import theme from '@/theme';

const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  const client = useApollo();

  return (
    <>
      <Helmet>
        <link
          href={`/fonts/neutrif-pro/stylesheet.css`}
          rel='stylesheet'
        />
      </Helmet>
      <RouterProvider>
        <ChakraProvider portalZIndex={9999} resetCSS={false} theme={theme}>
          <ApolloProvider client={client}>{children}</ApolloProvider>
        </ChakraProvider>
      </RouterProvider>
    </>
  );
};

export default AppProviders;
