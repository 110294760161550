import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { AppConfig } from '@/types/domain.d';

type AppConfigContextType = {
  config: AppConfig;
  isOpen: boolean;
  toggle: () => void;
};

export const defaultConfig: AppConfig = {
  apiKey: 'default',
  formId: 'default',
  selector: 'Formsy__widget',
};

const AppConfigContext = React.createContext<AppConfigContextType>({
  config: defaultConfig,
  isOpen: true,
  toggle: () => undefined,
});

const AppConfigProvider: FC<{ children: ReactNode; appConfig: AppConfig }> = ({
  children,
  appConfig,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [config, setAppConfig] = useState<AppConfig>({
    ...defaultConfig,
    ...appConfig,
  });


  useEffect(() => {
    if (!window.Formsy) {
      window.Formsy = {} as any;
    }

    window.Formsy.updateConfig = (...args) => {
      if (
        document.querySelector(`${config.selector} .formsy-screen-root` as string)
      ) {
        setAppConfig(...args);
        return true;
      }
      return false;
    };
    return () => {
      window.Formsy.updateConfig = undefined;
    };
  }, []);

  const toggle = useCallback(() => setIsOpen(v => !v), []);

  return (
    <AppConfigContext.Provider
      value={{
        config,
        isOpen,
        toggle,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

function useAppConfig(): AppConfigContextType {
  const context = React.useContext(AppConfigContext);
  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider');
  }
  return context;
}

export { AppConfigProvider, useAppConfig };
