const scale = {
  xs: '0.25rem',
  sm: '0.5rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '3rem',
};
const array = [0, scale.xs, scale.sm, scale.md, scale.lg, scale.xl];
export default {
  ...array,
  ...scale,
};
