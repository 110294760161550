import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type CreateFormsyClientInput = {
  name: Scalars['String']['input'];
  userId: Scalars['Float']['input'];
};

export type CreateFormsyFormInput = {
  clientId: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Formsy = {
  __typename?: 'Formsy';
  config: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Float']['output'];
  formId: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  style: Scalars['JSON']['output'];
  version: Scalars['Float']['output'];
};

export type FormsyClient = {
  __typename?: 'FormsyClient';
  clientApiKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type FormsyForm = {
  __typename?: 'FormsyForm';
  clientId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  idToken: Scalars['String']['output'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  createFormsyClient: FormsyClient;
  createFormsyForm: FormsyForm;
  createUser: User;
  login: LoginResponse;
  register: User;
  resendVerificationEmail: Scalars['Boolean']['output'];
  saveFormsy: Formsy;
  verifyEmail: Scalars['Boolean']['output'];
};


export type MutationCreateFormsyClientArgs = {
  options: CreateFormsyClientInput;
};


export type MutationCreateFormsyFormArgs = {
  options: CreateFormsyFormInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationRegisterArgs = {
  registerInput: RegisterInput;
};


export type MutationResendVerificationEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSaveFormsyArgs = {
  saveFormsyInput: SaveFormsyInput;
};


export type MutationVerifyEmailArgs = {
  id: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  formsy?: Maybe<Formsy>;
  formsyClients: Array<FormsyClient>;
  formsyForms: Array<FormsyForm>;
  formsys: Array<Formsy>;
  self: User;
  user?: Maybe<User>;
};


export type QueryFormsyClientsArgs = {
  options: QueryFormsyClientsInput;
};


export type QueryFormsyFormsArgs = {
  options: QueryFormsyFormsInput;
};


export type QueryFormsysArgs = {
  options: QueryFormsysInput;
};


export type QueryUserArgs = {
  id: Scalars['Float']['input'];
};

export type QueryFormsyClientsInput = {
  userId: Scalars['Float']['input'];
};

export type QueryFormsyFormsInput = {
  clientId?: InputMaybe<Scalars['Float']['input']>;
  formId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryFormsysInput = {
  formId: Scalars['String']['input'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type SaveFormsyInput = {
  config: Scalars['JSON']['input'];
  formId: Scalars['String']['input'];
  style: Scalars['JSON']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firebaseId: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
};

/** User role */
export enum UserRole {
  Admin = 'Admin',
  User = 'User'
}

export type FormsyQueryVariables = Exact<{ [key: string]: never; }>;


export type FormsyQuery = { __typename?: 'Query', formsy?: { __typename?: 'Formsy', id: number, createdAt: any, createdById: number, formId: string, config: any, style: any, version: number } | null };


export const FormsyDocument = gql`
    query formsy {
  formsy {
    id
    createdAt
    createdById
    formId
    config
    style
    version
  }
}
    `;

/**
 * __useFormsyQuery__
 *
 * To run a query within a React component, call `useFormsyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsyQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormsyQuery(baseOptions?: Apollo.QueryHookOptions<FormsyQuery, FormsyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsyQuery, FormsyQueryVariables>(FormsyDocument, options);
      }
export function useFormsyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsyQuery, FormsyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsyQuery, FormsyQueryVariables>(FormsyDocument, options);
        }
export function useFormsySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormsyQuery, FormsyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsyQuery, FormsyQueryVariables>(FormsyDocument, options);
        }
export type FormsyQueryHookResult = ReturnType<typeof useFormsyQuery>;
export type FormsyLazyQueryHookResult = ReturnType<typeof useFormsyLazyQuery>;
export type FormsySuspenseQueryHookResult = ReturnType<typeof useFormsySuspenseQuery>;
export type FormsyQueryResult = Apollo.QueryResult<FormsyQuery, FormsyQueryVariables>;