import { FC } from 'react';

import Router from '@/components/Router';
import { AppConfigProvider } from '@/context/AppConfigContext';
import AppProviders from '@/context/AppProvider';
import { AppConfig } from '@/types/domain.d';

const App: FC<{ config: AppConfig }> = ({ config }) => {
  return (
    <AppConfigProvider appConfig={config}>
      <AppProviders>
        <Router />
      </AppProviders>
    </AppConfigProvider>
  );
};

export default App;
